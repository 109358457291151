import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Bio from "../components/bio"

class Work extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Work" />
        <div className="work">
          <h2>Work</h2>

          <h3>Dotdash Meredith</h3>

          <p>Food utility tool: <a href="//www.myrecipes.com" target="blank">MyRecipes</a></p>
          <p>Ad products / sponsored content: <a href="//www.verywellhealth.com/featured/teg2/better-psoriatic-arthritis-treatment" target="blank">Verywell Health</a>, <a href="//www.liquor.com/featured/featured/bartender-of-the-year" target="blank">Liquor.com</a>, <a href="//www.byrdie.com/featured/featured/a-celebration-of-black-beauty" target="blank">Byrdie</a>, <a href="//www.thebalancemoney.com/featured/featured/ultimate-homebuying-guide" target="blank">The Balance</a></p>

          <h3>Engine Digital</h3>

          <p>Shopify ecommerce sites: <a href="//zoneoffroad.com/" target="blank">Zone Offroad</a> / <a href="//www.raceface.com/" target="blank">RaceFace</a> (Fox Factory), <a href="//www.loloirugs.com/" target="blank">Loloi Rugs</a></p>
          <p>Ecommerce replatforms: <a href="//www.concordtheatricals.com/" target="blank">Concord Theatricals / Samuel French</a></p>
          <p>Marketing sites: <a href="//www.3-form.com/" target="blank">3form</a></p>

          <h3>Forbes Media</h3>

          <p>Lists: <a href="//www.forbes.com/30-under-30-2017/" target="blank">Forbes 30 Under 30</a>, <a href="//www.forbes.com/forbes-400/" target="blank">Forbes 400</a>, <a href="//www.forbes.com/self-made-women/" target="blank">Self-Made Women</a></p>
          <p>Sponsored content: <a href="//www.forbes.com/united/street-to-street/" target="blank">United Airlines</a>, <a href="//www.forbes.com/delltechnologies/changeagents/" target="blank">Dell</a>, <a href="//www.forbes.com/oppenheimerfunds/the-new-opportunities/" target="blank">Oppenheimer Funds</a>, <a href="//www.forbes.com/vanguard/destination-retirement/" target="blank">Vanguard</a>, <a href="//www.forbes.com/hennessy/moments-of-precision/" target="blank">Hennessy</a></p>
          <p>Verticals: Forbes Podcasts</p>

          <h2>Resumé</h2>

          <a href="/ah-resume.pdf" download>
            <Button marginTop="35px">Download</Button>
          </a>

          <h2>Skills</h2>

          <h4>Data Science</h4>

          <ul>
            <li>Python (Pandas, NumPy)</li>
            <li>Data Visualization (Matplotlib, Seaborn, Plotly)</li>
            <li>Machine Learning (scikit-learn, TensorFlow)</li>
            <li>SQL</li>
            <li>Jupyter Notebook</li>
          </ul>

          <h4>Web Development & Agile</h4>

          <ul>
            <li>JavaScript/ES6</li>
            <li>HTML5, CSS3/SCSS/Sass, BEM, Tailwind</li>
            <li>Vue 3/Pinia, Vue.js/Vuex, React/Redux, AngularJS/2, Laravel</li>
            <li>Nuxt, GatsbyJS, Shopify</li>
            <li>Prismic, WordPress, Netlify</li>
            <li>Git, GitHub, Bitbucket</li>
            <li>Jira, Confluence, Miro</li>
            <li>Web Accessibility, Responsive Design, Performance (Web Vitals)</li>
          </ul>

          <h4>Design</h4>

          <ul>
            <li>Photoshop, Illustrator</li>
            <li>Figma, Sketch, InVision</li>
          </ul>

          <h3>Contact</h3>

          <a href="mailto:mr.andrew.huang@gmail.com">
            <Button marginTop="35px" marginBottom="35px">Email</Button>
          </a>
          <Bio />
        </div>
      </Layout>
    )
  }
}

export default Work

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
